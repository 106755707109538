import React from "react"
import Video from "../components/Video"
import VideoMobile from "../components/VideoMobile"

export default function MercedesPage(props) {
  return (
    <div id="because-its-mercedes">
      <VideoMobile
        title="Mercedes, Because it's Mercedes • Directed by Arnaud Bressond"
        src="https://player.vimeo.com/video/1014773856?title=0&muted=0"
        allowsInlineMediaPlayback="true"
      />
      <Video
        title="Mercedes, Because it's Mercedes • Directed by Arnaud Bresson"
        src="https://player.vimeo.com/video/1014773856?background=1&autoplay=1&loop=1&byline=0&title=0&muted=0"
      />
    </div>
  )
}
